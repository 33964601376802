import { gql } from 'graphql-tag';

gql`
  query userWorkerStat($userId: String!, $search: String) {
    userWorkerStat(userId: $userId, search: $search) {
      all
      online
      offline
      invalid
    }
  }
`;

export enum WorkerStatusEnum {
  online = 1,
  offline = 2,
  invalid = 3,
}
