import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// import { makeStyles, Theme, createStyles } from '@mui/styles';
import ReactDOM from 'react-dom';
import Loading from 'components/Loading';
import styled from 'styled-components';

const StyledSnackbar = styled(Snackbar)`
  .MuiAlert-icon {
    width: 24px;
    height: 24px;
  }
  .MuiAlert-filledError {
    border-radius: 16px;
    background: rgba(188, 60, 64, 0.9);

    & .muialert-action {
      align-items: flex-start;
      padding-top: 8px;
    }
  }
  .MuiAlert-filledInfo {
    border-radius: 16px;
    background: rgba(101, 218, 134, 1);
    & .MuiAlert-message {
      color: #060816;
    }
  }
  .MuiAlert-filledWarning {
    border-radius: 16px;
    background: rgba(234, 185, 98, 1);
  }
  .MuiAlert-filledSuccess {
    border-radius: 16px;
    background: rgba(101, 218, 134, 1);
    & .MuiAlert-message a {
      font-weight: 600;
      color: #fff;
      line-height: 17px;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .MuiButtonBase-root {
    color: #fff;
  }

  .MuiAlertTitle-root {
    font-size: 20px;
    line-height: 22px;
  }

  .text {
    color: #fff;
    font-size: 16px;
  }

  .MuiAlert-action {
  }
`;

export type AlertType = AlertColor | 'loading';

export interface AlertProps {
  type?: AlertType;
  isOpen?: boolean;
  title?: string;
  text: string;
  onClose(): void;
  key?: string;
  duration?: number;
  anchorOrigin?: {
    vertical: 'top' | 'bottom';
    horizontal: 'center' | 'left' | 'right';
  };
}

export default function CustomAlert({
  type = 'success',
  title,
  text,
  isOpen = false,
  //   key = 'global-alert',
  onClose,
  duration = 10000,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
}: AlertProps) {
  // const classes = useStyles();

  return (
    <StyledSnackbar
      // className={classes.root}
      open={!!isOpen}
      key={'global-alert'}
      autoHideDuration={duration}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      ClickAwayListenerProps={{
        mouseEvent: false,
      }}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={onClose}
        icon={false}
        severity={type === 'loading' ? 'warning' : type}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        <div className="text">{text}</div>
      </Alert>
    </StyledSnackbar>
  );
}
