import React from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 600px);
`;
export default function ComingSoon() {
  return (
    <Wrapper>
      <TYPE.gMain fontSize={96} uppercase textAlign={'center'} mobileFz={48}>
        Coming Soon
      </TYPE.gMain>
    </Wrapper>
  );
}
