export function catchError(
  target: Object,
  propertyKey: string,
  descriptor: PropertyDescriptor,
) {
  const originalMethod = descriptor.value;

  descriptor.value = async function (...args: any[]) {
    try {
      console.log(`Calling method ${propertyKey}:`, args);
      return await originalMethod.apply(this, args);
    } catch (error) {
      console.error(`Error in method ${propertyKey}:`, error);
      console.error(`parameters:`, args);
    }
  };
}
