import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { NavLink, useParams } from 'react-router-dom';
import { TYPE } from 'theme';
import Row, { RowBetween, RowCenter } from 'components/Row';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table';

import { default as OnlineStatusIcon } from 'assets/img/status-online.svg';
import { default as OfflineStatusIcon } from 'assets/img/status-offline.svg';
import { default as InvalidStatusIcon } from 'assets/img/status-invalid.svg';

import Table from 'components/Table';
import { observer } from 'mobx-react';
import user from 'stores/user';
import { formatNumber, formatSpeed } from 'utils';

import { poolRequestApi } from 'gql';
import {
  OrderDirection,
  WorkerBaseInfo,
  WorkerOrderKey,
  WorkerStat,
} from 'gql/pool/__generated__/request';
import { WorkerStatusEnum } from 'gql/pool/userWorkerState';
import { Overview, OverviewItem } from 'components/Overview';
import Select from 'components/Select';
import Value from 'components/Value';
import { isMobile } from 'react-device-detect';
import SearchWorker from './SearchWorker';
import LineChart from './SpeedChart';

export type Person = {
  firstName: string;
  lastName: string;
  email: string;
  city: string;
};

const MachineStatusTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  gap: 20px;
  height: 100%;
  flex-shrink: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
  `};
`;

const MachindStatus = styled.div`
  padding: 10px 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  color: #fff;
  font-size: 16px;
  background: #3c334c;
  border-radius: 8px 8px 8px 8px;
  cursor: pointer;
  height: 100%;
  flex-shrink: 0;

  &.active {
    color: #000;
    font-weight: 600;
    background: linear-gradient(90deg, #66cebb 0%, #5eafc1 50%, #876fa6 100%);
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const StyledMachineStatusRow = styled(RowCenter)`
  margin-top: 40px;
  height: 44px;
  gap: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 20px;
    `};
`;

const StatusRow = styled(RowCenter)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: flex-start;
    `};
`;

const statusMap = {
  [WorkerStatusEnum.online]: {
    text: 'Online',
    icon: <OnlineStatusIcon />,
    color: '#00f0ff',
  },
  [WorkerStatusEnum.offline]: {
    text: 'Offline',
    icon: <OfflineStatusIcon />,
    color: '#f5980d',
  },
  [WorkerStatusEnum.invalid]: {
    text: 'Invalid',
    icon: <InvalidStatusIcon />,
    color: '#f5184c',
  },
};

const StyledSelect = styled(Select)`
  width: 200px;
`;

function MinersTable() {
  const { t, i18n } = useTranslation();
  const columns: MRT_ColumnDef<WorkerBaseInfo>[] = [
    {
      accessorKey: 'workerName', //access nested data with dot notation
      header: t('Worker Name'),
    },
    {
      accessorKey: 'computeValue',
      header: t('Speed(Estimated)'),
      sortingFn: (a, b) => (a as any).computeValue - (b as any).computeValue,
      accessorFn: (row) =>
        `${formatSpeed(row.computeValue)} ${row.computeUnit}`,
    },
    {
      accessorKey: 'localComputeValue',
      header: t('Local Speed'),
      sortingFn: (a, b) =>
        (a as any).localComputeValue - (b as any).localComputeValue,
      accessorFn: (row) =>
        `${formatSpeed(row.localComputeValue)} ${row.computeUnit}`,
    },
    {
      accessorKey: 'shareValue',
      header: t('Submitted Solutions'),
      sortingFn: (a, b) => (a as any).shareValue - (b as any).shareValue,
      accessorFn: (row) => formatNumber(row.shareValue),
    },
    {
      accessorKey: 'lastUpdate',
      header: t('Last submit time'),
      sortingFn: (a, b) => (a as any).lastUpdate - (b as any).lastUpdate,
      accessorFn: (row) => moment(row.lastUpdate).format('YYYY/MM/DD HH:mm:ss'),
    },
    {
      accessorKey: 'status',
      header: t('Online Status'),
      size: 100,
      enableSorting: false,
      // accessorFn: (row) => t((statusMap as any)[row.status].text),
      accessorFn: (row) => {
        const status = statusMap[row.status as WorkerStatusEnum];
        return (
          <StatusRow style={{ color: status?.color }}>
            {status?.icon}
            &nbsp;{t(status?.text)}
          </StatusRow>
        );
      },
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      muiTableFooterCellProps: {
        align: 'center',
      },
    },
  ];
  const [data, setData] = useState<WorkerBaseInfo[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [workerStatusFilter, setWorkerStatusFilter] = useState<number>();
  const [userWorkerStat, setUserWorkerStat] = useState<WorkerStat>();
  const [searchWorkName, setSearchWorkName] = useState<string>('');
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  //   const table = useMaterialReactTable();
  useEffect(() => {
    const fetchData = async () => {
      if (!user.userInfo) {
        setData([]);
        setUserWorkerStat(undefined);
        return;
      }
      //   if (!data.length) {
      //     setIsLoading(true);
      //   } else {
      //     setIsRefetching(true);
      //   }
      setIsLoading(true);
      try {
        const workersRes = await poolRequestApi.userWorkers({
          userId: user.userInfo?.id,
          skip: pagination.pageIndex * pagination.pageSize,
          limit: pagination.pageSize,
          status: workerStatusFilter,
          orderBy: sorting.length
            ? (sorting[0].id as WorkerOrderKey)
            : undefined,
          orderDirection:
            sorting.length && !sorting[0].desc
              ? OrderDirection.Asc
              : OrderDirection.Desc,
          search: searchWorkName.trim(),
        });

        setData(workersRes?.userWorkers?.workers || []);
        setRowCount(workersRes?.userWorkers?.total || 0);
        setUserWorkerStat(workersRes?.userWorkerStat);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    const interval = setInterval(() => {
      if (isMobile) return;
      setIsRefetching(true);
      fetchData();
    }, 300000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.pageIndex, //re-fetch when page index changes
    pagination.pageSize, //re-fetch when page size changes
    sorting, //re-fetch when sorting changes,
    user.userInfo,
    workerStatusFilter,
    isMobile,
    i18n.language,
    searchWorkName,
  ]);

  useEffect(() => {
    let interval: any;
    if (user.id) {
      interval = setInterval(() => {
        user.getUserInfo(user.id);
      }, 60000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [user.id]);

  const tableConfig = {
    columns,
    data,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      isLoading: isLoading && !isRefetching,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
      pagination,
    },
    toggleResetExpand: useMemo(() => {
      return `${searchWorkName}${workerStatusFilter}`;
    }, [searchWorkName, workerStatusFilter]),
    enableExpandAll: false,
    enableExpandOnlyOne: true,
    muiExpandButtonProps: ({ row, table }: any) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //set only this row to be expanded
    }),

    renderDetailPanel: (data: any) => {
      return (
        <LineChart
          userId={user.userInfo?.id}
          workerName={
            isMobile ? data?.workerName : data?.row?.original?.workerName
          }
          isShow={!isMobile ? data?.row?.getIsExpanded() : true}
        />
      );
    },
    muiTableBodyRowProps: ({ row, table }: any) => ({
      onClick: (event: any) => {
        table.setExpanded({ [row.id]: !row.getIsExpanded() });
      },
    }),
    memoMode: 'cells',
  };

  const StatusConfig = [
    {
      text: `${t('All')} ${userWorkerStat ? ` (${userWorkerStat.all})` : ''}`,
      value: undefined,
      key: 'all',
    },
    {
      text: `${t('Online')} ${
        userWorkerStat ? ` (${userWorkerStat.online})` : ''
      }`,
      value: WorkerStatusEnum.online,
      key: 'online',
    },
    {
      text: `${t('Offline')} ${
        userWorkerStat ? ` (${userWorkerStat.offline})` : ''
      }`,
      value: WorkerStatusEnum.offline,
      key: 'offline',
    },
    {
      text: `${t('Invalid')} ${
        userWorkerStat ? ` (${userWorkerStat.invalid})` : ''
      }`,
      value: WorkerStatusEnum.invalid,
      key: 'invalid',
    },
  ];

  const MobileSelectComponent = useCallback(() => {
    return (
      <StyledSelect
        items={StatusConfig}
        disablePlaceholder
        value={workerStatusFilter}
        onChange={setWorkerStatusFilter}
      />
    );
  }, [StatusConfig, workerStatusFilter, workerStatusFilter]);

  return (
    <>
      <Overview>
        <OverviewItem>
          <RowCenter
            style={{
              gap: '4px',
              alignItems: 'baseline',
            }}
          >
            <TYPE.gMain fontSize={28} mobileFz={16}>
              {user.userInfo ? (
                <Value value={user.userInfo.todayIncomes} decimals={2} />
              ) : (
                '-'
              )}
            </TYPE.gMain>
            <TYPE.main fontSize={14}>Aleo</TYPE.main>
          </RowCenter>
          <TYPE.desc textAlign={'center'}>
            {t('Today’s Revenue(Estimated)')}
          </TYPE.desc>
        </OverviewItem>
        <OverviewItem>
          <RowCenter
            style={{
              gap: '4px',
              alignItems: 'baseline',
            }}
          >
            <TYPE.gMain fontSize={28} mobileFz={16}>
              {userWorkerStat ? <Value value={userWorkerStat.all} /> : '-'}
            </TYPE.gMain>
          </RowCenter>
          <TYPE.desc textAlign={'center'}>{t('Total Workers')}</TYPE.desc>
        </OverviewItem>
        <OverviewItem>
          <RowCenter
            style={{
              gap: '4px',
              alignItems: 'baseline',
            }}
          >
            <TYPE.gMain fontSize={28} mobileFz={16}>
              {userWorkerStat ? <Value value={userWorkerStat?.online} /> : '-'}
            </TYPE.gMain>
          </RowCenter>
          <TYPE.desc textAlign={'center'}>{t('Online')}</TYPE.desc>
        </OverviewItem>
        <OverviewItem>
          <RowCenter
            style={{
              gap: '4px',
              alignItems: 'baseline',
            }}
          >
            <TYPE.gMain fontSize={28} mobileFz={16}>
              {user.userInfo ? (
                <Value
                  value={user.userInfo.realtimeHashRate}
                  speed
                  decimals={2}
                />
              ) : (
                '-'
              )}{' '}
            </TYPE.gMain>
            <TYPE.main fontSize={14}>
              {user.userInfo?.realtimeHashRateUnit}
            </TYPE.main>
          </RowCenter>
          <TYPE.desc textAlign={'center'}>{t('Speed')}</TYPE.desc>
        </OverviewItem>
      </Overview>
      <StyledMachineStatusRow>
        <SearchWorker value={searchWorkName} onChange={setSearchWorkName} />
        <MachineStatusTab>
          <MachindStatus
            className={!workerStatusFilter ? 'active' : ''}
            onClick={() => {
              setWorkerStatusFilter(undefined);
            }}
          >
            {t('All')}
            {userWorkerStat ? ` (${userWorkerStat.all})` : ''}
          </MachindStatus>
          <MachindStatus
            className={workerStatusFilter === 1 ? 'active' : ''}
            onClick={() => {
              setWorkerStatusFilter(1);
            }}
          >
            {t('Online')}
            {userWorkerStat ? ` (${userWorkerStat.online})` : ''}
          </MachindStatus>
          <MachindStatus
            className={workerStatusFilter === 2 ? 'active' : ''}
            onClick={() => {
              setWorkerStatusFilter(2);
            }}
          >
            {t('Offline')}
            {userWorkerStat ? ` (${userWorkerStat.offline})` : ''}
          </MachindStatus>
          <MachindStatus
            className={workerStatusFilter === 3 ? 'active' : ''}
            onClick={() => {
              setWorkerStatusFilter(3);
            }}
          >
            {t('Invalid')}
            {userWorkerStat ? ` (${userWorkerStat.invalid})` : ''}
          </MachindStatus>
        </MachineStatusTab>
      </StyledMachineStatusRow>

      <Table
        table={tableConfig as any}
        mobileToolComponent={<MobileSelectComponent />}
      />
    </>
  );
}

export default observer(MinersTable);
