import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { TYPE } from 'theme';
import StorageIcon from '@mui/icons-material/Storage';
import PaidIcon from '@mui/icons-material/Paid';
import { RowBetween, RowCenter } from 'components/Row';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react';
import user from 'stores/user';

import MinerTable from './MinerTable';
import IncomeTable from './IncomeTable';
import SearchMiner from 'components/SearchMiner';
import PageTitle from 'components/PageTItle';
import Copy from 'components/Copy';
import { RemoveSVG } from 'assets';

export type Person = {
  firstName: string;
  lastName: string;
  email: string;
  city: string;
};

const Wrapper = styled.div`
  padding: 10px;
  .MuiBreadcrumbs-separator {
    color: #fff;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.c.t02};
  text-decoration: none;
`;

const Border = styled.div`
  width: 100%;
  height: 2px;
  border: 1px solid;
  margin: 20px 0;
  border-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.48),
      rgba(255, 255, 255, 0)
    )
    1 1;
`;

const StyleTabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  gap: 30px;
  width: 100%;
  margin-top: 60px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 30px;
  `};
`;

const Tab = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;

  &.active {
    div {
      background: linear-gradient(
        90deg,
        rgba(123, 61, 235, 1),
        rgba(47, 208, 238, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
    }
    border-bottom: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(123, 61, 235, 1),
        rgba(47, 208, 238, 1)
      )
      4 4;
    div {
      font-weight: 800 !important;
    }
  }
`;

const ContentBox = styled.div`
  width: 100%;
  display: none;
  margin-top: 40px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 20px;
  `};

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &.show {
    display: block;
    animation: fadeIn 0.5s;
  }
`;

const AddressWrapper = styled.div`
  width: 960px;
  height: 88px;
  background: linear-gradient(90deg, #349da6 0%, #3a1d52 100%);
  border-radius: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin: 0 auto;
  margin-top: 80px;
  padding: 10px 20px;

  .address {
    word-wrap: break-word;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  height: fit-content;
  margin-top: 40px;
  padding: 10px 20px;
  gap: 10px;
  `};
`;

const StyledRemoveSVG = styled(RemoveSVG)`
  cursor: pointer;
  flex-shrink: 0;
`;

const StyledAddressButtonWrapper = styled(RowCenter)`
  width: fit-content;
  gap: 20px;
  flex-shrink: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  gap: 8px;
 `};
`;

function Miners() {
  const { id } = useParams<{ id: string }>();
  const [tab, setTab] = useState(1);
  const [searchAddress, setSearchAddress] = useState<string>(id);
  const { t } = useTranslation();
  const history = useHistory();
  const [isShowSearch, setIsShowSearch] = useState(false);

  useEffect(() => {
    user.setId(id);
    setSearchAddress(id);

    return () => {
      user.clearData();
    };
  }, [id]);

  function search() {
    if (searchAddress) {
      history.push(`/machine/${searchAddress}`);
    }
  }
  return (
    <Wrapper>
      <PageTitle hideText={isShowSearch} />
      <AddressWrapper>
        <TYPE.main
          fontSize={20}
          mobileFz={16}
          fontWeight={600}
          textAlign={'center'}
          style={{ flexGrow: 1 }}
          className="address"
        >
          {id}
        </TYPE.main>
        <StyledAddressButtonWrapper>
          <Copy text={id} />
          <StyledRemoveSVG
            onClick={() => {
              setIsShowSearch(true);
            }}
          />
        </StyledAddressButtonWrapper>
      </AddressWrapper>
      {isShowSearch ? (
        <SearchMiner
          active
          onCompleteSearch={() => {
            setIsShowSearch(false);
          }}
        />
      ) : (
        <></>
      )}
      <StyleTabWrapper>
        <Tab
          className={tab === 1 ? 'active' : ''}
          onClick={() => {
            setTab(1);
          }}
        >
          <TYPE.main fontSize={20} fontWeight={600} uppercase>
            {t('Workers')}
          </TYPE.main>
        </Tab>
        <Tab
          className={tab === 2 ? 'active' : ''}
          onClick={() => {
            setTab(2);
          }}
        >
          <TYPE.main fontSize={20} fontWeight={600} uppercase>
            {t('Income')}
          </TYPE.main>
        </Tab>
      </StyleTabWrapper>

      <ContentBox className={tab === 1 ? 'show' : ''}>
        <MinerTable />
      </ContentBox>
      <ContentBox className={tab === 2 ? 'show' : ''}>
        <>{tab === 2 && <IncomeTable />}</>
      </ContentBox>
    </Wrapper>
  );
}

export default observer(Miners);
