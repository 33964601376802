import i18n, { loadResources } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from '../../public/locales/en/translation.json';
import zhCN from '../../public/locales/zh-CN/translation.json';
import jp from '../../public/locales/jp/translation.json';
import kr from '../../public/locales/kr/translation.json';
import ru from '../../public/locales/ru/translation.json';
export default i18n
  //   .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en,
      },
      'zh-CN': {
        translation: zhCN,
      },
      jp: {
        translation: jp,
      },
      kr: {
        translation: kr,
      },
      ru: {
        translation: ru,
      },
    },
  });
