import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { FilterSVG, SelectedSVG } from 'assets';
import { TableProps } from './index';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import styled from 'styled-components';
import { ResetTableState } from './MobileTable';
import Row from 'components/Row';
import { TYPE } from 'theme';
import { useTranslation } from 'react-i18next';

interface Props extends TableProps {
  resetTableState(state?: ResetTableState): void;
}

const StyledNorthIcon = styled(NorthIcon)`
  color: ${({ theme }) => theme.c.t02};
  height: 18px;
`;

const StyledSouthIcon = styled(SouthIcon)`
  color: ${({ theme }) => theme.c.t02};
  height: 18px;
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .selectedSVG {
    opacity: 0;
  }
  &.active {
    .text {
      background: linear-gradient(90deg, #7b3deb 0%, #2fd0ee 70%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
    }

    svg {
      color: #2fd0ee;
    }

    .selectedSVG {
      opacity: 1;
    }
  }
`;

const StyleMenu = styled(Menu)`
  .MuiList-root {
    padding: 10px;
    .filterTitle {
      padding-bottom: 8px;
    }
  }
`;

const StyledSelected = styled(SelectedSVG)`
  color: #2fd0ee;
  flex-shrink: 0;
  width: 20px;
`;

export default function MobileTools({ table, resetTableState }: Props) {
  const { t } = useTranslation();
  const { columns, onSortingChange, state } = table;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const sortingItems = React.useMemo(() => {
    return columns.filter((item) => item.enableSorting !== false);
  }, [columns]);

  function handleSortingClick(key: string, desc: boolean) {
    return () => {
      if (onSortingChange) {
        resetTableState({
          sorting: [
            {
              id: key,
              desc: desc,
            },
          ],
        });
      }
      handleClose();
    };
  }

  function getSortClassName(item: any, desc: boolean) {
    if (
      state?.sorting?.[0]?.id === item.accessorKey &&
      state?.sorting?.[0]?.desc === desc
    ) {
      return 'active';
    }
    return '';
  }

  if (!sortingItems.length) return null;

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? 'filterMenu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <FilterSVG />
      </Button>
      <StyleMenu
        id="filterMenu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <TYPE.main className="filterTitle">{t('Sort by')}</TYPE.main>
        {sortingItems.map((item) => (
          <>
            <StyledMenuItem
              className={getSortClassName(item, true)}
              onClick={handleSortingClick(item.accessorKey as string, true)}
              key={`${item.accessorKey}desc`}
            >
              <Row className="text">
                {item.header} <StyledSouthIcon />
              </Row>
              <StyledSelected className="selectedSVG" />
            </StyledMenuItem>
            <StyledMenuItem
              className={getSortClassName(item, false)}
              onClick={handleSortingClick(item.accessorKey as string, false)}
              key={`${item.accessorKey}asc`}
            >
              <Row className="text">
                {item.header} <StyledNorthIcon />
              </Row>
              <StyledSelected className="selectedSVG" />
            </StyledMenuItem>
          </>
        ))}
      </StyleMenu>
    </div>
  );
}
