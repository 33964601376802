import { makeAutoObservable } from 'mobx';
import { Confirm } from 'components/Dialog';
import { SnackbarOrigin } from '@mui/material';
import { AlertProps, AlertType } from 'components/Alert';

const globalkey = 'global-alert';
type ActionProps = Omit<AlertProps, 'onClose'>;
export class Alert {
  type?: AlertType = 'success';
  isOpen?: boolean = false;
  text: string = '';
  duration?: number = 5000;
  key?: string = globalkey;
  anchorOrigin?: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };
  constructor() {
    makeAutoObservable<this>(this);
  }

  open = ({ type, text, duration, key }: ActionProps) => {
    this.isOpen = true;
    this.type = type;
    this.text = text;
    this.duration = duration;
    this.key = key;
  };

  success = (props: ActionProps) => {
    this.open({
      type: 'success',
      ...props,
      key: globalkey,
    });
  };

  error = (props: ActionProps) => {
    this.open({
      type: 'error',
      ...props,
      key: globalkey,
    });
  };

  close = () => {
    this.isOpen = false;
    setTimeout(() => {
      this.type = 'success';
      this.text = '';
      this.duration = 5000;
      this.key = globalkey;
      this.anchorOrigin = {
        vertical: 'top',
        horizontal: 'center',
      };
    }, 500);
  };
}

export default new Alert();
