import { poolRequestApi } from 'gql';
import { UserInfo, WorkerStat } from 'gql/pool/__generated__/request';
import { action, autorun, makeAutoObservable } from 'mobx';
import { catchError } from 'utils/decorators';
import alert from './alert';
import { t } from 'i18next';

export class User {
  id?: string = undefined;
  userInfo?: UserInfo = undefined;
  userWorkerStat?: WorkerStat = undefined;
  constructor() {
    makeAutoObservable<this>(this);
    autorun(() => {
      if (this.id) {
        const id = this.id;
        this.getUserInfo(id);
        // this.getWorkerState(id);
      }
    });
  }

  @action
  async getUserInfo(userId?: string) {
    try {
      if (!userId) return;
      const res = await poolRequestApi.userInfo({
        userId,
        _notCatchedBefore: true,
      } as any);
      this.setUserInfo(res?.userInfo);
    } catch (error: any) {
      console.error(error);
      alert.error({
        title: 'Error',
        text: t('Miner does not exist'),
      });
    }
  }

  @catchError
  @action
  async getWorkerState(userId?: string) {
    if (!userId) return;
    const res = await poolRequestApi.userWorkerStat({
      userId,
    });
    this.setUserWorkerStat(res?.userWorkerStat);
  }

  setUserWorkerStat = (workerState?: WorkerStat) => {
    this.userWorkerStat = workerState;
  };

  setUserInfo = (userInfo?: UserInfo) => {
    this.userInfo = userInfo;
  };

  setId = (id: string) => {
    this.id = id;
  };

  clearData = () => {
    this.id = undefined;
    this.userInfo = undefined;
    this.userWorkerStat = undefined;
  };
}
export default new User();
