import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ThemeProvider, { ThemedGlobalStyle } from './theme';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ApolloMultiClientProvider } from './ApolloMultiClientProvider';

import 'utils/i18n';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <ApolloMultiClientProvider>
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <ThemedGlobalStyle />
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </ApolloMultiClientProvider>,
);
