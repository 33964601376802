import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TYPE } from 'theme';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table';

import Table from 'components/Table';
import { observer } from 'mobx-react';
import { poolRequestApi } from 'gql';
import {
  IncomeOrderKey,
  OrderDirection,
  UserIncome,
} from 'gql/pool/__generated__/request';
import { Overview, OverviewItem } from '../../components/Overview';
import user from 'stores/user';
import { RowCenter } from 'components/Row';
import { formatNumber } from 'utils';
import Value from 'components/Value';

export type Person = {
  firstName: string;
  lastName: string;
  email: string;
  city: string;
};

function IncomeTable() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const columns: MRT_ColumnDef<UserIncome>[] = [
    {
      accessorKey: 'timestamp',
      header: t('Settlement day'),
      sortingFn: (a, b) => (a as any).timestamp - (b as any).timestamp,
      accessorFn: (row) => moment(row.timestamp).format('YYYY/MM/DD'),
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      muiTableFooterCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: 'amount', //access nested data with dot notation
      header: t('Amount'),
      accessorFn: (row) => `${formatNumber(row.amount)} Aleo`,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      muiTableFooterCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: 'totalShares', //access nested data with dot notation
      header: t('Total solutions'),
      accessorFn: (row) => formatNumber(row.totalShares),
      enableSorting: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      muiTableFooterCellProps: {
        align: 'center',
      },
    },
  ];
  const [data, setData] = useState<UserIncome[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchData = async () => {
      //   if (!data.length) {
      //     setIsLoading(true);
      //   } else {
      //     setIsRefetching(true);
      //   }
      setIsLoading(true);

      try {
        const res = await poolRequestApi.userIncomes({
          userId: id,
          skip: pagination.pageIndex * pagination.pageSize,
          limit: pagination.pageSize,
          orderBy: sorting.length
            ? (sorting[0].id as IncomeOrderKey)
            : undefined,
          orderDirection:
            sorting.length && !sorting[0].desc
              ? OrderDirection.Asc
              : OrderDirection.Desc,
        });

        setData(res?.userIncomes?.incomes || []);
        setRowCount(res?.userIncomes?.total || 0);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.pageIndex, //re-fetch when page index changes
    pagination.pageSize, //re-fetch when page size changes
    sorting, //re-fetch when sorting changes,
    id,
  ]);

  const tableConfig = {
    columns,
    data,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      isLoading: isLoading && !isRefetching,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
      isRefetching,
    },
  };

  return (
    <>
      <Overview>
        <OverviewItem>
          <RowCenter
            style={{
              gap: '4px',
              alignItems: 'baseline',
            }}
          >
            <TYPE.main fontSize={28} mobileFz={16}>
              {user.userInfo ? (
                <Value value={user.userInfo.totalIncomes} decimals={2} />
              ) : (
                '-'
              )}
            </TYPE.main>
            <TYPE.main fontSize={14}>Aleo</TYPE.main>
          </RowCenter>
          <TYPE.desc textAlign={'center'}>{t('Total Income')}</TYPE.desc>
        </OverviewItem>
        <OverviewItem>
          <RowCenter
            style={{
              gap: '4px',
              alignItems: 'baseline',
            }}
          >
            <TYPE.main fontSize={28} mobileFz={16}>
              {user.userInfo ? (
                <Value value={user.userInfo.paid} decimals={2} />
              ) : (
                '-'
              )}
            </TYPE.main>
            <TYPE.main fontSize={14}>Aleo</TYPE.main>
          </RowCenter>
          <TYPE.desc textAlign={'center'}>{t('Paid')}</TYPE.desc>
        </OverviewItem>
        <OverviewItem>
          <RowCenter
            style={{
              gap: '4px',
              alignItems: 'baseline',
            }}
          >
            <TYPE.main fontSize={28} mobileFz={16}>
              {user.userInfo ? (
                <Value value={user.userInfo.unpaid} decimals={2} />
              ) : (
                '-'
              )}
            </TYPE.main>
            <TYPE.main fontSize={14}>Aleo</TYPE.main>
          </RowCenter>
          <TYPE.desc textAlign={'center'}>{t('Unpaid')}</TYPE.desc>
        </OverviewItem>
        <OverviewItem>
          <RowCenter
            style={{
              gap: '4px',
              alignItems: 'baseline',
            }}
          >
            <TYPE.main fontSize={28} mobileFz={16}>
              {user.userInfo ? (
                <Value value={user.userInfo.todayIncomes} decimals={2} />
              ) : (
                '-'
              )}
            </TYPE.main>
            <TYPE.main fontSize={14}>Aleo</TYPE.main>
          </RowCenter>
          <TYPE.desc textAlign={'center'}>
            {t('Today’s Revenue(Estimated)')}
          </TYPE.desc>
        </OverviewItem>
      </Overview>
      <Table table={tableConfig as any} />
    </>
  );
}

export default observer(IncomeTable);
