import { CopySVG } from 'assets';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useCopyToClipboard } from 'usehooks-ts';
import DoneIcon from '@mui/icons-material/Done';
const Wrapper = styled.div``;

interface Props {
  text: string;
  clsssName?: string;
}

const StyledCopySVG = styled(CopySVG)`
  cursor: pointer;
  flex-shrink: 0;
`;

const StyledDoneIcon = styled(DoneIcon)`
  color: green;
  cursor: pointer;
`;
export default function Copy({ text, clsssName }: Props) {
  const [isDone, setIsDone] = useState(false);
  const [copiedText, copy] = useCopyToClipboard();
  const handleCopy = (text: string) => {
    copy(text)
      .then(() => {
        setIsDone(true);
        setTimeout(() => setIsDone(false), 1000);
      })
      .catch((error) => {
        console.error('Failed to copy!', error);
      });
  };
  return isDone ? (
    <StyledDoneIcon className={clsssName} />
  ) : (
    <StyledCopySVG
      clsssName={clsssName}
      onClick={() => {
        handleCopy(text);
      }}
    />
  );
}
