import React from 'react';
import { observer } from 'mobx-react';
import Alert from './index';
import alert from 'stores/alert';

function GlobalAlert() {
  const {
    type,
    text,
    isOpen,
    key,
    close,
    duration = 10000,
    anchorOrigin,
  } = alert;
  return (
    <Alert
      isOpen={isOpen}
      type={type}
      key={key}
      onClose={close}
      text={text}
      duration={duration}
      anchorOrigin={anchorOrigin}
    />
  );
}

export default observer(GlobalAlert);
