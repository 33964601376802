import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
interface Props {
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}

const StyledLoading = styled(CircularProgress)`
  @keyframes rotateAnimation {
    100% {
      transform: rotate(360deg);
    }
  }

  color: #22eee2;
  animation: rotateAnimation 2s linear infinite;
`;

export default function Loading({
  size = 24,
  style: cssStyle,
  className,
}: Props) {
  const sizeStyle = size
    ? {
        width: `${size}px`,
        height: `${size}px`,
      }
    : undefined;
  return (
    <StyledLoading
      style={{
        ...cssStyle,
        ...sizeStyle,
      }}
      className={className}
    />
  );
}
