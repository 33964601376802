{
  "pooldesc": "Высокооптимизированный пул для максимальной производительности",
  "pooldescVIP": "Гипероптимизированный пул для максимальной производительности",
  "Network Speed": "Скорость сети",
  "Pool Speed": "Скорость бассейна",
  "Pool rewards": "Награды в пуле",
  "Miners count": "Майнеры на счету",
  "Solutions count": "Решения имеют значение",
  "Workers count": "Количество рабочих",
  "Search Miner": "Поиск майнера",
  "Enter Miner Address": "Введите адрес майнера",
  "Search": "Искать",
  "Real-time speed": "Скорость в реальном времени",
  "Today mined": "Сегодня добыто",
  "Worker Name": "Имя воркера",
  "Speed": "Скорость",
  "Submitted Solutions": "Представленные решения",
  "Last submit time": "Время последней отправки",
  "Online Status": "Статус в сети",
  "Settlement day": "Расчетный день",
  "Amount": "Количество",
  "Home": "Дом",
  "All": "Все",
  "Online": "Онлайн",
  "Offline": "Автономный",
  "Invalid": "Недопустимый",
  "Miners": "Шахтеров",
  "Income": "Доход",
  "Total Income": "Общий доход",
  "Account Balance": "Счете",
  "Today’s Revenue(Estimated)": "Сегодняшняя выручка(оценочная)",
  "No records": "Нет записей",
  "Miner": "Горняк",
  "runCmdJoin": "Выполните следующую команду в терминале, чтобы присоединиться к пулу",
  "Pool": "Бассейн",
  "homepageBottomDesc": "Cysic Network — это защищенный от ZK слой, который гипермасштабирует революцию ZK. Основной целью этого является использование ведущих разработок CUDA и ASIC от Cysic, а также вычислительных ресурсов сообщества, чтобы ускорить генерацию доказательств ZK и расчеты для всех проектов ZK.",
  "Workers": "Работников",
  "Total Workers": "Всего рабочих",
  "Current Epoch": "Современная эпоха",
  "Block Height": "Высота блока",
  "Workers Count": "Количество рабочих",
  "somethingWrong": "Что-то не так, пожалуйста, повторите попытку позже",
  "Miner does not exist": "Майнера не существует",
  "Address not found": "Адрес не найден",
  "Total solutions": "Комплексные решения",
  "Join": "Соединять",
  "Rows per page": "Строк на странице",
  "Detailed tutorial": "Подробное руководство",
  "Total Payouts": "Общая сумма выплат",
  "Search History": "История поиска",
  "How to join": "Как присоединиться",
  "No more data": "Больше нет данных",
  "Sort by": "Сортировать по",
  "Pool Fee": "Комиссия за пул",
  "Search by worker name": "Поиск по имени воркера",
  "Reduce Pool Fee": "Уменьшите комиссию пула",
  "Local Speed": "Местная скорость",
  "Speed(Estimated)": "Скорость (Оценочная)",
  "Language": "Язык",
  "More": "Больше",
  "Last 24 hours speed": "Скорость за последние 24 часа",
  "Paid": "Оплачиваемый",
  "Unpaid": "Неоплаченный",
  "Average": "Средний"
}