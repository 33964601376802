import { poolRequestApi } from 'gql';
import { Overview } from 'gql/pool/__generated__/request';
import { action, autorun, makeAutoObservable } from 'mobx';
import { catchError } from 'utils/decorators';

export class Application {
  overview?: Overview = undefined;
  constructor() {
    makeAutoObservable<this>(this);
    autorun(() => {
      this.getOverview();
    });
  }
  @catchError
  @action
  async getOverview() {
    const overview = await poolRequestApi.overview();
    this.setOverview(overview?.overview);
  }

  setOverview = (overview?: Overview) => {
    this.overview = overview;
  };
}

export default new Application();
