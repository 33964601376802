import { TextField } from '@mui/material';
import { SearchSVG } from 'assets';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDebounceCallback } from 'usehooks-ts';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
const Wrapper = styled.div`
  flex-grow: 1;
  height: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  `};
  .MuiTextField-root {
    width: 100%;
    height: 100%;
    .MuiInputBase-root {
      border: 1px solid ${({ theme }) => theme.c.t02};
      border-radius: 8px;
      width: 100%;
      height: 100%;

      fieldset {
        border: none !important;
      }

      input {
        padding: 0 16px;
        height: 100%;
        color: ${({ theme }) => theme.c.t01};
      }
    }
  }
`;

const StyledHighlightOffIcon = styled(HighlightOffIcon)`
  cursor: pointer;
  color: #fff;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
`;

const StyledSearchIcon = styled(SearchSVG)`
  width: 27px;
  height: 27px;
  color: ${({ theme }) => theme.c.t02};
  margin-right: 10px;
`;

interface Props {
  value: string;
  onChange(value: string): void;
}
export default function SearchWorker({ value, onChange }: Props) {
  const { t } = useTranslation();
  const [input, setInput] = useState(value);
  const debounced = useDebounceCallback(onChange, 500);

  function setValue(value: string) {
    setInput(value);
    debounced(value);
  }
  return (
    <Wrapper>
      <TextField
        placeholder={t('Search by worker name')}
        className={'input'}
        value={input}
        variant="outlined"
        onChange={(e) => {
          setValue(e.currentTarget.value);
        }}
        label=""
        InputProps={{
          startAdornment: <StyledSearchIcon />,
          endAdornment: input ? (
            <StyledHighlightOffIcon
              onClick={() => {
                setValue('');
              }}
            />
          ) : null,
        }}
      />
    </Wrapper>
  );
}
