import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
const abortController = new AbortController();

import { onError } from '@apollo/client/link/error';
import alert from 'stores/alert';
// import store from 'utils/storage';
import {
  getSdk as getPoolSdk,
  SdkFunctionWrapper,
} from './pool/__generated__/request';
import { GraphQLClient } from 'graphql-request';
import { POOL_GRAPH } from 'const';

// const authMiddleware = new ApolloLink((operation, forward) => {
//   const user = store.get('user');
//   let jwtAccessToken = '';
//   if (user) {
//     jwtAccessToken = user.token;
//   }
//   console.log('graph jwttoken', jwtAccessToken);
//   operation.setContext({
//     headers: {
//       Authorization: `Bearer ${jwtAccessToken}`,
//     },
//   });

//   return forward(operation);
// });

function generateApolloClient(uri: string) {
  return new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: ApolloLink.from([
      //   authMiddleware,

      onError(({ graphQLErrors, networkError }) => {
        if (networkError) {
          setTimeout(() => {
            alert.error({
              title: 'Network error',
              text: networkError?.message || 'Something went wrong.',
            });
          }, 0);
          console.error(`[Network error]: ${networkError}`);
        }

        if (graphQLErrors) {
          setTimeout(() => {
            alert.error({
              title: 'Error',
              text: graphQLErrors[0]?.message || 'Something went wrong.',
            });
          }, 0);
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        }
      }),
      new HttpLink({
        uri: uri,
        fetchOptions: {
          signal: abortController.signal,
        },
      }),
    ]),
  });
}

export const poolApolloClient = generateApolloClient(POOL_GRAPH || '');

const sdkWrapper: SdkFunctionWrapper = async (
  action: () => Promise<any>,
  operationName: string,
  operationType?: string,
  variables?: any,
): Promise<any> => {
  return action().catch((error) => {
    if (variables?._notCatchedBefore) {
      throw error;
    } else {
      console.error('query error, method:', operationName, variables || '');
      console.log(JSON.stringify(error));
      setTimeout(() => {
        alert.error({
          title: 'Error',
          text: error?.response?.errors?.length
            ? error?.response?.errors[0]?.message
            : 'Something went wrong.',
        });
      }, 0);
    }
  });
};

export const poolRequestApi = getPoolSdk(
  new GraphQLClient(POOL_GRAPH || ''),
  sdkWrapper,
);
