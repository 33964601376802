import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import Numeral from 'numeral';
export function formatDateTime(timestamp: number): string {
  return dayjs(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
}

export const fileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export function isLocal() {
  return (
    ['https://localhost', 'https://127.0.0.1'].indexOf(
      window.location.origin,
    ) >= 0
  );
}

export function formatNumber(num?: number | string, roundDown: boolean = true) {
  if (roundDown) {
    num = formatRounddownNumber(num || 0);
  }
  if (num && Number(num) > 1000000) {
    return Numeral(num).format(' 0.[00]a').toLocaleUpperCase();
  }

  return Numeral(num).format('0,0.[00]');
}

export function formatSpeed(num?: number | string) {
  const n = Number(num) || 0;
  if (n < 1000) {
    return `${num}`;
  } else if (n < 1e6) {
    return `${(n / 1000).toFixed(2)} K`;
  } else if (n < 1e9) {
    return `${(n / 1e6).toFixed(2)} M`;
  } else if (n < 1e12) {
    return `${(n / 1e9).toFixed(2)} G`;
  } else if (n < 1e15) {
    return `${(n / 1e12).toFixed(2)} T`;
  } else if (n < 1e18) {
    return `${(n / 1e15).toFixed(2)} P`;
  } else if (n < 1e21) {
    return `${(n / 1e18).toFixed(2)} E`;
  } else if (n < 1e24) {
    return `${(n / 1e21).toFixed(2)} Z`;
  } else {
    return `${(n / 1e24).toFixed(2)} Y`;
  }
}

export function formatRounddownNumber(num: Number | string, decimal = 2) {
  return Number(new BigNumber(Number(num)).toFixed(decimal, 1));
}
