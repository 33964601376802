{
    "pooldesc": "Highly Optimized Pool for Ultimate Performance",
    "pooldescVIP": "Hyper Optimized Pool for Ultimate Performance",
    "Network Speed": "Network Speed",
    "Pool Speed": "Pool Speed",
    "Pool rewards": "Pool rewards",
    "Miners count": "Miners count",
    "Solutions count": "Solutions count",
    "Workers count": "Workers count",
    "Search Miner": "Search Miner",
    "Enter Miner Address": "Enter Miner Address",
    "Search": "Search",
    "Real-time speed": "Real-time speed",
    "Today mined": "Today mined",
    "Worker Name": "Worker Name",
    "Speed": "Speed",
    "Submitted Solutions": "Submitted Solutions",
    "Last submit time": "Last submit time",
    "Online Status": "Online Status",
    "Settlement day": "Settlement day",
    "Amount": "Amount",
    "Home": "Home",
    "All": "All",
    "Online": "Online",
    "Offline": "Offline",
    "Invalid": "Invalid",
    "Miners": "Miners",
    "Income": "Income",
    "Total Income": "Total Income",
    "Account Balance": "Account Balance",
    "Today’s Revenue(Estimated)": "Today’s Revenue(Estimated)",
    "No records": "No records",
    "Miner": "Miner",
    "runCmdJoin": "Run the following command in your terminal to join a pool",
    "Pool": "Pool",
    "homepageBottomDesc": "Cysic Network is a ZK-proof layer that hyperscales the ZK revolution. The main purpose of it is using Cysic’s leading CUDA and ASIC development, and the computing resources from the community, to expedite the ZK proof generation and settlement for all ZK projects.",
    "Workers": "Workers",
    "Total Workers": "Total Workers",
    "Current Epoch": "Current Epoch",
    "Block Height": "Block Height",
    "Workers Count": "Workers Count",
    "somethingWrong": "Something Wrong, please try again later",
    "Miner does not exist": "Miner does not exist",
    "Address not found": "Address not found",
    "Total solutions": "Total solutions",
    "Join": "Join",
    "Rows per page": "Rows per page",
    "Detailed tutorial": "Detailed tutorial",
    "Total Payouts": "Total Payouts",
    "Search History": "Search History",
    "How to join": "How to join",
    "No more data": "No more data",
    "Sort by": "Sort by",
    "Pool Fee": "Pool Fee",
    "Search by worker name": "Search by worker name",
    "Reduce Pool Fee": "Reduce Pool Fee",
    "Local Speed": "Local Speed",
    "Speed(Estimated)": "Speed(Estimated)",
    "Language": "Language",
    "More": "More",
    "Last 24 hours speed": "Last 24 hours speed",
    "Paid": "Paid",
    "Unpaid": "Unpaid",
    "Average": "Average"
}