import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type EpochInfo = {
  currentBlockInEpoch: Scalars['Int']['output'];
  currentEpoch: Scalars['Int']['output'];
  totalBlocksInEpoch: Scalars['Int']['output'];
};

export enum IncomeOrderKey {
  Timestamp = 'timestamp'
}

export type IncomesList = {
  incomes: Array<Maybe<UserIncome>>;
  total: Scalars['Int']['output'];
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

/** homepage overview */
export type Overview = {
  /** current block height */
  blockHeight: Scalars['Float']['output'];
  /** epoch info */
  epochInfo: EpochInfo;
  /** miner count */
  minerCount: Scalars['Int']['output'];
  /** global network hashrate */
  networkHashrate: Scalars['Float']['output'];
  /** network hash rate unit */
  networkHashrateUnit: Scalars['String']['output'];
  /** pool fee */
  poolFee: Scalars['Float']['output'];
  /** pool hashrate unit */
  poolHashRateUnit: Scalars['String']['output'];
  /** pool hashrate */
  poolHashrate: Scalars['Float']['output'];
  /** pool rewards */
  poolRewards: Scalars['Float']['output'];
  /** solution count */
  solutionCount: Scalars['String']['output'];
  /** worker count */
  workerCount: Scalars['Int']['output'];
};

export type Query = {
  overview: Overview;
  userIncomes: IncomesList;
  userInfo?: Maybe<UserInfo>;
  userWorker?: Maybe<WorkerDetail>;
  userWorkerStat: WorkerStat;
  userWorkers: WorkersList;
};


export type QueryUserIncomesArgs = {
  limit: Scalars['Int']['input'];
  orderBy?: InputMaybe<IncomeOrderKey>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};


export type QueryUserInfoArgs = {
  userId: Scalars['String']['input'];
};


export type QueryUserWorkerArgs = {
  userId: Scalars['String']['input'];
  workerName: Scalars['String']['input'];
};


export type QueryUserWorkerStatArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type QueryUserWorkersArgs = {
  limit: Scalars['Int']['input'];
  orderBy?: InputMaybe<WorkerOrderKey>;
  orderDirection?: InputMaybe<OrderDirection>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
  status?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['String']['input'];
};

export type UserIncome = {
  /** amount */
  amount: Scalars['Float']['output'];
  /** timestamp */
  timestamp: Scalars['Float']['output'];
  /** total shares */
  totalShares: Scalars['Float']['output'];
};

export type UserInfo = {
  /** balance */
  balance: Scalars['Float']['output'];
  /** user id */
  id: Scalars['String']['output'];
  /** paid */
  paid: Scalars['Float']['output'];
  /** realtime hashrate */
  realtimeHashRate: Scalars['Float']['output'];
  /** realtime hashrate unit */
  realtimeHashRateUnit: Scalars['String']['output'];
  /** today incomes */
  todayIncomes: Scalars['Float']['output'];
  /** total incomes */
  totalIncomes: Scalars['Float']['output'];
  /** total payouts */
  totalPayouts: Scalars['Float']['output'];
  /** unpaid */
  unpaid: Scalars['Float']['output'];
};

/** worker */
export type WorkerBaseInfo = {
  /** compute unit */
  computeUnit: Scalars['String']['output'];
  /** compute value */
  computeValue: Scalars['Float']['output'];
  /** worker id */
  id: Scalars['String']['output'];
  /** last update timestamp */
  lastUpdate: Scalars['Float']['output'];
  /** compute value */
  localComputeValue: Scalars['Float']['output'];
  /** miner id */
  minerId: Scalars['String']['output'];
  /** share value */
  shareValue: Scalars['Float']['output'];
  /**
   * status
   *     1: online
   *     2: offline
   *     3: invalid
   */
  status: Scalars['Int']['output'];
  /** worker name */
  workerName: Scalars['String']['output'];
};

export type WorkerCharts = {
  /** worker local compute value charts */
  localComputeValuesCharts: Array<Maybe<WorkerComputeValueDetail>>;
  /** worker pool compute value charts */
  poolComputeValuesCharts: Array<Maybe<WorkerComputeValueDetail>>;
};

/** worker compute value detail */
export type WorkerComputeValueDetail = {
  timestamp: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type WorkerDetail = {
  baseInfo: WorkerBaseInfo;
  id: Scalars['String']['output'];
  workerCharts: WorkerCharts;
};


export type WorkerDetailWorkerChartsArgs = {
  startTime: Scalars['Float']['input'];
};

export enum WorkerOrderKey {
  ComputeValue = 'computeValue',
  LastUpdate = 'lastUpdate',
  LocalComputeValue = 'localComputeValue',
  ShareValue = 'shareValue',
  WorkerName = 'workerName'
}

export type WorkerStat = {
  all: Scalars['Int']['output'];
  invalid: Scalars['Int']['output'];
  offline: Scalars['Int']['output'];
  online: Scalars['Int']['output'];
};

export type WorkersList = {
  total: Scalars['Int']['output'];
  workers: Array<Maybe<WorkerBaseInfo>>;
};

export type OverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type OverviewQuery = { overview: { networkHashrate: number, networkHashrateUnit: string, poolHashrate: number, poolHashRateUnit: string, poolRewards: number, minerCount: number, solutionCount: string, workerCount: number, blockHeight: number, poolFee: number, epochInfo: { currentEpoch: number, currentBlockInEpoch: number, totalBlocksInEpoch: number } } };

export type UserIncomesQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  skip: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy?: InputMaybe<IncomeOrderKey>;
  orderDirection?: InputMaybe<OrderDirection>;
}>;


export type UserIncomesQuery = { userIncomes: { total: number, incomes: Array<{ timestamp: number, amount: number, totalShares: number }> } };

export type UserInfoQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type UserInfoQuery = { userInfo?: { id: string, totalIncomes: number, paid: number, unpaid: number, todayIncomes: number, realtimeHashRate: number, realtimeHashRateUnit: string } };

export type UserWorkerQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  workerName: Scalars['String']['input'];
  startTime: Scalars['Float']['input'];
}>;


export type UserWorkerQuery = { userWorker?: { id: string, baseInfo: { computeUnit: string, computeValue: number, id: string, lastUpdate: number, localComputeValue: number, minerId: string, shareValue: number, status: number, workerName: string }, workerCharts: { localComputeValuesCharts: Array<{ timestamp: number, value: number }>, poolComputeValuesCharts: Array<{ timestamp: number, value: number }> } } };

export type UserWorkerStatQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type UserWorkerStatQuery = { userWorkerStat: { all: number, online: number, offline: number, invalid: number } };

export type UserWorkersQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  skip: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  status?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WorkerOrderKey>;
  orderDirection?: InputMaybe<OrderDirection>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type UserWorkersQuery = { userWorkers: { total: number, workers: Array<{ computeUnit: string, computeValue: number, id: string, lastUpdate: number, localComputeValue: number, minerId: string, shareValue: number, status: number, workerName: string }> }, userWorkerStat: { all: number, online: number, offline: number, invalid: number } };


export const OverviewDocument = gql`
    query overview {
  overview {
    networkHashrate
    networkHashrateUnit
    poolHashrate
    poolHashRateUnit
    poolRewards
    minerCount
    solutionCount
    workerCount
    epochInfo {
      currentEpoch
      currentBlockInEpoch
      totalBlocksInEpoch
    }
    blockHeight
    poolFee
    workerCount
  }
}
    `;
export const UserIncomesDocument = gql`
    query userIncomes($userId: String!, $skip: Int!, $limit: Int!, $orderBy: IncomeOrderKey, $orderDirection: OrderDirection) {
  userIncomes(
    userId: $userId
    skip: $skip
    limit: $limit
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    total
    incomes {
      timestamp
      amount
      totalShares
    }
  }
}
    `;
export const UserInfoDocument = gql`
    query userInfo($userId: String!) {
  userInfo(userId: $userId) {
    id
    totalIncomes
    paid
    unpaid
    todayIncomes
    realtimeHashRate
    realtimeHashRateUnit
  }
}
    `;
export const UserWorkerDocument = gql`
    query userWorker($userId: String!, $workerName: String!, $startTime: Float!) {
  userWorker(userId: $userId, workerName: $workerName) {
    baseInfo {
      computeUnit
      computeValue
      id
      lastUpdate
      localComputeValue
      minerId
      shareValue
      status
      workerName
    }
    id
    workerCharts(startTime: $startTime) {
      localComputeValuesCharts {
        timestamp
        value
      }
      poolComputeValuesCharts {
        timestamp
        value
      }
    }
  }
}
    `;
export const UserWorkerStatDocument = gql`
    query userWorkerStat($userId: String!, $search: String) {
  userWorkerStat(userId: $userId, search: $search) {
    all
    online
    offline
    invalid
  }
}
    `;
export const UserWorkersDocument = gql`
    query userWorkers($userId: String!, $skip: Int!, $limit: Int!, $status: Int, $orderBy: WorkerOrderKey, $orderDirection: OrderDirection, $search: String) {
  userWorkers(
    userId: $userId
    skip: $skip
    limit: $limit
    status: $status
    orderBy: $orderBy
    orderDirection: $orderDirection
    search: $search
  ) {
    total
    workers {
      computeUnit
      computeValue
      id
      lastUpdate
      localComputeValue
      minerId
      shareValue
      status
      workerName
    }
  }
  userWorkerStat(userId: $userId, search: $search) {
    all
    online
    offline
    invalid
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    overview(variables?: OverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OverviewQuery>(OverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'overview', 'query', variables);
    },
    userIncomes(variables: UserIncomesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserIncomesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserIncomesQuery>(UserIncomesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'userIncomes', 'query', variables);
    },
    userInfo(variables: UserInfoQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserInfoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserInfoQuery>(UserInfoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'userInfo', 'query', variables);
    },
    userWorker(variables: UserWorkerQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserWorkerQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserWorkerQuery>(UserWorkerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'userWorker', 'query', variables);
    },
    userWorkerStat(variables: UserWorkerStatQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserWorkerStatQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserWorkerStatQuery>(UserWorkerStatDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'userWorkerStat', 'query', variables);
    },
    userWorkers(variables: UserWorkersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserWorkersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserWorkersQuery>(UserWorkersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'userWorkers', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;