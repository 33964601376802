import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { TableProps } from './index';
import { TYPE } from 'theme';
import Loading from 'components/Loading';
import { useLocation } from 'react-router-dom';
import MobileFilter from './MobileTooles';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';
import { MRT_ColumnDef } from 'material-react-table';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
`;

const DataItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 10px 0;
  width: 100%;
  border-radius: 16px;

  &:nth-child(2n) {
    background: #17131e;
  }
  &:nth-child(2n + 1) {
    background: #25222b;
  }
`;

const DataRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0 10px;
  width: 100%;
  > div {
    width: calc(50% - 10px);
    flex-shrink: 0;
    flex-grow: 0;
  }
`;
const ToolsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  .custom {
    width: fit-content;
  }
`;

const StyleRowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #fff;
  }
`;

export interface ResetTableState {
  sorting: {
    id: string;
    desc?: boolean;
  }[];
}

function Card({
  columns,
  data,
  enableExpandAll,
  renderDetailPanel,
  enableExpandOnlyOne,
  onClickExpand,
  currentEnableExpand,
}: {
  columns: MRT_ColumnDef<any, any>[];
  data: any[];
  enableExpandAll?: boolean;
  renderDetailPanel?: (item: any) => React.ReactNode;
  enableExpandOnlyOne: boolean;
  onClickExpand: () => void;
  currentEnableExpand: boolean;
}) {
  const [isOpenMore, setIsOpenMore] = useState(false);

  const computedIsOpenMore = useMemo(() => {
    if (!enableExpandOnlyOne) {
      return isOpenMore;
    }

    return currentEnableExpand;
  }, [isOpenMore, currentEnableExpand, enableExpandOnlyOne]);
  return (
    <DataItem className="dataitem">
      {columns.map((columnConfig, kIndex) => (
        <DataRow key={kIndex}>
          <TYPE.desc uppercase textAlign={'right'}>
            {columnConfig.header}
          </TYPE.desc>
          <TYPE.main
            fontSize={14}
            textAlign={'left'}
            style={{ justifyItems: 'flex-start' }}
          >
            {columnConfig.accessorFn
              ? columnConfig.accessorFn(data)
              : data[columnConfig.accessorKey as any]}
          </TYPE.main>
        </DataRow>
      ))}
      {enableExpandAll !== undefined && !!renderDetailPanel && (
        <>
          {computedIsOpenMore && renderDetailPanel(data)}
          <StyleRowCenter
            onClick={() => {
              if (enableExpandOnlyOne) {
                onClickExpand();
              } else {
                setIsOpenMore(!isOpenMore);
              }
            }}
          >
            {computedIsOpenMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </StyleRowCenter>
        </>
      )}
    </DataItem>
  );
}

export default function MobileTable({
  table,
  mobileToolComponent,
}: TableProps) {
  const {
    data,
    columns,
    state,
    rowCount,
    onPaginationChange,
    onSortingChange,
    renderEmptyRowsFallback,
    enableExpandAll,
    renderDetailPanel,
  } = table;
  const enableExpandOnlyOne = (table as any).enableExpandOnlyOne;
  const [tableData, setTableData] = useState<any[]>([]);
  const location = useLocation();
  const preLocation = useRef<any>();
  const { t } = useTranslation();
  const [innerLoading, setInnerLoading] = useState(false);
  const [expandIndex, setExpandIndex] = useState<number>();
  useEffect(() => {
    const dom: Element | null = document.querySelector('.container');
    const scroll = throttle(() => {
      if (dom) {
        const { scrollTop, clientHeight, scrollHeight } = dom;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          if (state?.pagination && rowCount) {
            if (
              state?.pagination?.pageIndex <
              Math.floor(rowCount / state?.pagination?.pageSize)
            ) {
              if (onPaginationChange) {
                setInnerLoading(true);
                setTimeout(() => {
                  onPaginationChange({
                    pageSize: state?.pagination?.pageSize || 10,
                    pageIndex: (state?.pagination?.pageIndex || 0) + 1,
                  });
                  setInnerLoading(false);
                }, 1000);
              }
            }
          }
        }
      }
    }, 500);
    if (dom) {
      dom.addEventListener('scroll', scroll);
    }

    return () => {
      if (dom) {
        dom.removeEventListener('scroll', scroll);
      }
    };
  }, [state?.pagination, rowCount]);

  useEffect(() => {
    if (state?.pagination) {
      tableData.splice(
        state.pagination.pageIndex * state.pagination.pageSize,
        state.pagination.pageSize,
        ...data,
      );
      setTableData([...tableData]);
    }
  }, [data, state?.pagination]);

  function resetTableState(resetState?: ResetTableState) {
    setTableData([]);
    setTimeout(() => {
      if (onPaginationChange && state?.pagination) {
        onPaginationChange({
          pageSize: state?.pagination?.pageSize,
          pageIndex: 0,
        });
      }

      if (onSortingChange) {
        onSortingChange((resetState?.sorting as any) || []);
      }
    }, 0);
  }

  useEffect(() => {
    if (location !== preLocation.current && state?.isLoading) {
      preLocation.current = location;

      resetTableState();
    }
  }, [location, state?.isLoading, state?.pagination]);

  return (
    <Wrapper>
      <ToolsWrapper>
        <div className="custom">{mobileToolComponent}</div>
        <MobileFilter resetTableState={resetTableState} table={table} />
      </ToolsWrapper>
      {tableData.map((item, index) => (
        <Card
          columns={columns}
          data={item}
          key={index}
          renderDetailPanel={renderDetailPanel}
          enableExpandAll={enableExpandAll}
          onClickExpand={() => {
            if (enableExpandOnlyOne) {
              setExpandIndex(expandIndex === index ? undefined : index);
            }
          }}
          currentEnableExpand={expandIndex === index}
          enableExpandOnlyOne={enableExpandOnlyOne}
        />
      ))}
      {(state?.isLoading || innerLoading) && <Loading />}
      {!state?.isLoading &&
        !tableData.length &&
        (renderEmptyRowsFallback as any)()}
      {!!rowCount &&
        rowCount === tableData.length &&
        !innerLoading &&
        !state?.isLoading && (
          <TYPE.desc fontSize={14} textAlign={'center'}>
            {t('No more data')}
          </TYPE.desc>
        )}
    </Wrapper>
  );
}
