/* eslint-disable react-hooks/exhaustive-deps */
import BigNumber from 'bignumber.js';
import Numeral from 'numeral';
import React, { useState, useEffect, useMemo } from 'react';
import CountUp from 'react-countup';
import styled from 'styled-components';
import { formatSpeed } from 'utils';

interface ValueProps {
  value: string | number;
  decimals?: number;
  defaultZero?: string;
  speed?: boolean;
  rounddown?: boolean;
}

const StyledCountUp = styled(CountUp)`
  font-family: inherit;
`;

export default function Value({
  value,
  decimals = 0,
  speed,
  rounddown = true,
}: ValueProps) {
  const [start, updateStart] = useState(0);
  const [end, updateEnd] = useState(0);
  const copyValue = rounddown
    ? new BigNumber(value).toFixed(decimals, 1)
    : value;

  useEffect(() => {
    updateStart(end);
    updateEnd(Number(copyValue));
  }, [copyValue]);

  if (speed) {
    return formatSpeed(copyValue);
  }

  return (
    <>
      {copyValue && Number(copyValue) > 1000000 ? (
        Numeral(copyValue).format(' 0.[00]a').toUpperCase()
      ) : (
        <StyledCountUp
          start={start}
          end={end}
          decimals={decimals}
          duration={0.5}
          separator=","
        />
      )}
    </>
  );
}

export function ValueFormat({
  value,
  decimals = 2,
}: {
  value: number;
  decimals?: number;
}) {
  const showValue = useMemo(() => {
    const sizes = [
      { num: 1, symbol: '' },
      { num: 1e3, symbol: 'K' },
      { num: 1e6, symbol: 'M' },
      { num: 1e9, symbol: 'B' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = sizes.length - 1; i > 0; i--) {
      if (value >= sizes[i].num) {
        break;
      }
    }
    return (
      ((value ? value : 0) / sizes[i].num).toFixed(decimals).replace(rx, '$1') +
      sizes[i].symbol
    );
  }, [value]);
  return <>{showValue}</>;
}
