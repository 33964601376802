import React from 'react';
import styled from 'styled-components';

export const Overview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  overflow: hidden;
  background-image: url(${require('assets/img/overview.png')});
  background-position: center;
  background-size: cover;
  padding: 40px 0;
  flex-wrap: wrap;

  > div {
    &:not(:last-child) {
      border-right: 1px solid #666666;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  > div {
   &:not(:last-child) {
      border: none;
    }
   }
  `};
`;

export const OverviewItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0 20px;
  padding: 20px;
  height: 60px;
  width: 25%;
  flex-shrink: 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: calc(50% - 10px);
  padding : 10px;
  `};
`;
